<template>
    <div>
        <jy-dialog type="table" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="'1000px'">
                <el-form :model="formData" ref="dialogForm" label-width="120px" :rules="rules">
                    <el-form-item label="线路" prop="routeId">
                        <el-select v-model="formData.routeId">
                            <el-option v-for="one in routeList" :key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="日期" prop="date">
                        <el-date-picker v-model="formData.date" type="daterange" :picker-options="expireTimeOption" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="司机" prop="drivers">
                        <el-input type="textarea" autosize v-model="formData.drivers" readonly @focus="choose"></el-input>
                    </el-form-item>
                    <el-form-item label="轮休周期" prop="rotation">
                        <el-input class="swidth" v-model="formData.rotation"></el-input> 天,轮休方式
                        <el-select class="swidth" v-model="formData.rotationType">
                            <el-option v-for="one in rotationTypeList" :key="one.value" :label="one.label" :value="one.value"></el-option>
                        </el-select><div style="display: inline-block;" v-if="formData.rotationType != '0'"> 上下午界限
                        <el-time-picker class="timewidth" value-format="HH:mm" format="HH:mm" v-model="formData.rotationTime"></el-time-picker></div>
                        <el-button class="btnr_right" type="primary" @click="timeRotation">轮休预览</el-button>
                    </el-form-item>
                </el-form>
            <div v-if="isRotationTable">
                <time-rotation ref="timeRotation" :driverList="driverList"></time-rotation>
            </div>
            <template #footer>
                <div class="footer_box">
                    <el-button @click="cancel">取消</el-button>
                    <el-button type="primary" @click="save">保存</el-button>
                </div>
            </template>
        </jy-dialog>
        <cardriver-selectbatch ref="cardriverSelectbatch" @choose="chooseList"></cardriver-selectbatch>
    </div>
</template>
<script>
import cardriverSelectbatch from '@/components/pages/admin/resourcesAllocation/carDriver/cardriverSelectbatch'
import timeRotation from '@/components/pages/admin/common/timeTable/timeRotation'
export default {
    data() {
        let validatePass = (rule, value, callback) => {
            console.log(this)
            if (!this.formData.rotation || (this.formData.rotationType != '0'&&!this.formData.rotationTime)) {
                callback(new Error('请完善轮休设置'));
            } else {
                callback();
            }
        };
        return {
            dialogVisible: false,
            title:'轮休设置',
            isRotationTable:false,
            formData: {
                routeId: '',
                date:[],
                drivers:'',
                rotation:'',
                rotationType:'0',
                rotationTime:'',
            },
            driverList:[],
            routeList: [],
            rotationTypeList:[
                {
                    label:'全天',
                    value:'0',
                },
                {
                    label:'上午',
                    value:'1',
                },
                {
                    label:'下午',
                    value:'2',
                },
            ],
            rules: {
                routeId: [
                    {
                        required: true,
                        message: "请选择线路",
                        trigger: "change",
                    },
                ],
                date: [
                    {
                        required: true,
                        message: "请选择日期",
                        trigger: "change",
                    },
                ],
                drivers: [
                    {
                        required: true,
                        message: "请选择司机",
                        trigger: "change",
                    },
                ],
                rotation: [
                    {
                        required: true,
                        message: "请完善轮休设置",
                        trigger: "change",
                    },
                    {
                        validator: validatePass,
                        trigger: 'change',
                    }
                ],
            },
            expireTimeOption: {
                disabledDate(date) {
                    return date.getTime() < Date.now() - 24 * 60 * 60 * 1000
                }
            },
        }
    },
    computed: {
    },
    components: {
        cardriverSelectbatch,
        timeRotation
    },
    created() {
        this.getRouteList()
    },
    watch: {
        dialogVisible(val){
            if(!val){
                this.resetForm()
            }
        },
        'formData.routeId'(){
            this.formData.drivers = ''
            this.driverList = []
        },
        'formData.rotationType'(val){
            if(val == '0'){
                this.formData.rotationTime = ''
            }
        }
    },
    methods: {
        init(routeId) {
            this.formData.routeId = routeId
            this.dialogVisible = true
        },
        getRouteList() {
            let url = '/route/info/queryRouteInfoByOrgIds'
            this.$http.post(url).then(({ detail }) => {
                this.routeList = detail
            })
        },
        resetForm() {
            this.isRotationTable = false
            this.driverList = []

            this.formData = {
                routeId: '',
                date:[],
                drivers:'',
                rotation:'',
                rotationType:'0',
                rotationTime:'',
            }
            this.$nextTick(()=>{
                this.$refs.dialogForm.clearValidate()
            })
        },
        cancel() {
            this.dialogVisible = false;
            console.log("cancel");
        },
        choose(){
            if(!this.formData.routeId){
                this.$message({
                    type: "warning",
                    message: '请先选择线路',
                });
                return false
            }
            this.$refs.cardriverSelectbatch.init(this.driverList,this.formData.routeId)
        },
        chooseList(list){
            this.driverList = list
            this.formData.drivers = list.map(item=>item.cName).join(',')
        },
        timeRotation(){
            this.$refs.dialogForm.validate(valid => {
                if (valid) {
                    this.isRotationTable = true
                    this.lxFun()
                    this.$nextTick(()=>{
                        this.$refs.timeRotation.init({
                            beginDate:this.formData.date[0],
                            endDate:this.formData.date[1],
                        })
                    })
                } else {
                    return false
                }
            })
        },
        lxFun(){
            let dateList = this.$util.getBetweenDateList(this.formData.date[0],this.formData.date[1])
            let key = 0
            let typeName = ''
            if(this.formData.rotationType == '0'){
                typeName = '休'
            }else if(this.formData.rotationType == '1'){
                typeName = '上午'
            }else{
                typeName = '下午'
            }
            this.driverList.forEach(item=>{
                item.driverNa = item.cName
                item.date = dateList.filter((one,k)=> k % this.formData.rotation == key).map(item=>{
                    return {
                        date: item.date,
                        typeName,
                    }
                })
                if(key == this.formData.rotation-1){
                    key = 0
                }else{
                    key++
                }
            })
            console.log(this.driverList)
        },

        save(){
            this.$refs.dialogForm.validate(valid => {
                if (valid) {
                    this.lxFun()
                    let list = this.driverList.map(item=>{
                        return {
                            driverId:item.driverId,
                            driverNa:item.driverNa,
                            rotationPlanTs:item.date.map(item=>item.date),
                            limitT:this.formData.rotationTime,
                            rotationPlanF:this.formData.rotationType,
                        }
                    })
                    let url = '/rotation/plan/add'
                    let option = {
                        routeId:this.formData.routeId,
                        routeNa:this.routeList.filter(one=>one.routeId==this.formData.routeId)[0].cName,
                        startTime:this.formData.date[0],
                        endTime:this.formData.date[1],
                        limitT:this.formData.rotationTime,
                        rotationPlanF:this.formData.rotationType,
                        rotationPlanAddBOList:list,
                    }
                    this.$http.post(url,option,{isRequestParam:false}).then(res=>{
                        this.$message({
                            type: "success",
                            message: res.msg,
                        });
                        this.$emit('updateList')
                        this.dialogVisible = false;
                    })
                } else {
                    return false
                }
            })
        },
    }
}

</script>
<style lang="scss" scoped="addRotationPlan">
.btnr_right{
    float: right;
}
.swidth{
    width: 100px;
}
.timewidth{
    width: 150px;
}
.footer_box{
    text-align: center;
}
</style>
