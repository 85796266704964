<template>
    <div id="rotationPlan">
        <jy-query ref="form" :model="formData">
            <jy-query-item prop="routeId" label="线路">
                <el-select v-model="formData.routeId">
                    <el-option v-for="one in routeList" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="addRotationPlan" v-if="btnexist('scheduleSchedulingRotationPlanSet')">轮休设置</el-button>
            </template>
        </jy-query>
        <time-rotation ref="timeRotation" @clickItem="clickItem" :driverList="driverList" @changeDate="changeDate"></time-rotation>
        <add-rotation-plan ref="addRotationPlan" @updateList="getList"></add-rotation-plan>
        <jy-dialog title="修改轮休时间" :visible.sync="dialogVisible" type="table" :width="'500px'">
           <div class="dia_box">
               <div>
                   <el-date-picker v-model="diaObj.rotationPlanT" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" :clearable="false"></el-date-picker>
                </div>
               <div>
                    <el-radio-group v-model="diaObj.rotationPlanF">
                        <el-radio label="0">休全天</el-radio>
                        <el-radio label="1">休上午</el-radio>
                        <el-radio label="2">休下午</el-radio>
                        <el-radio label="3">工作日</el-radio>
                    </el-radio-group>
               </div>
           </div>
            <template #footer>
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="save">确定</el-button>
            </template>
        </jy-dialog>
    </div>
</template>
<script>
import timeRotation from '@/components/pages/admin/common/timeTable/timeRotation'
import addRotationPlan from '@/components/pages/admin/scheduling/rotationPlan/addRotationPlan'
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            formData: {
                routeId: '',
            },
            routeList: [],


            beginDate:'',
            endDate:'',
            driverList:[],
            dialogVisible:false,
            diaObj:{
                id:'',
                rotationPlanF:'',
                rotationPlanT:'',
            },
            routeId:'',
            routeNa:'',
            rotationtype:'0',
            rotationdate:'',

            btnMenuId:'scheduleSchedulingRotationPlan',
        }
    },
    mixins:[btnMixins],
    components: {
        timeRotation,
        addRotationPlan,
    },
    watch:{
        'formData.routeId'(){
            this.getList()
        },
    },
    async created(){
        this.getDate()
        await this.getRouteList()
        this.getList()
    },
    methods:{
        async getRouteList() {
            let url = '/route/info/queryRouteInfoByOrgIds'
            await this.$http.post(url).then(({ detail }) => {
                this.routeList = detail || []
                if(this.routeList.length){
                    this.formData.routeId = this.routeList[0].routeId
                }
            })
        },
        getDate(){
            let now = new Date()
            let nowMonth = now.getMonth()
            let nowYear = now.getFullYear()
            this.beginDate = new Date(nowYear, nowMonth, 1).Format('yyyy-MM-dd')
            this.endDate = new Date(nowYear, nowMonth+1, 0).Format('yyyy-MM-dd')
            this.$nextTick(()=>{
                this.$refs.timeRotation.init({
                    beginDate:this.beginDate,
                    endDate:this.endDate,
                    type:'1',
                })
            })
        },
        getList() {
            let option = {
                ...this.formData,
                startTime:this.beginDate,
                endTime:this.endDate,
            }
            let url = '/rotation/plan/queryList'
            this.$http.post(url, option).then(({ detail }) => {
                console.log(detail)
                this.routeId = option.routeId
                this.routeNa = option.routeNa
                let driverList = []
                detail.forEach(item=>{
                    if(item.rotationPlanF == '0'){
                        item.typeName = '休'
                    }else if(item.rotationPlanF == '1'){
                        item.typeName = '上午'
                    }else{
                        item.typeName = '下午'
                    }
                    if(!driverList.some(one=>one.driverId == item.driverId)){
                        driverList.push(
                            {
                                driverId:item.driverId,
                                driverNa:item.driverNa,
                                date:[
                                    {
                                        date:item.rotationPlanT,
                                        id:item.id,
                                        typeName:item.typeName,
                                        rotationPlanF:item.rotationPlanF,
                                    }
                                ]
                            }
                        )
                    }else{
                        let obj = driverList.filter(one=>one.driverId == item.driverId)[0]
                        obj.date.push(
                            {
                                date:item.rotationPlanT,
                                id:item.id,
                                typeName:item.typeName,
                                rotationPlanF:item.rotationPlanF,
                            }
                        )
                    }
                })
                this.driverList = driverList
            })
        },
        addRotationPlan(){
            this.$refs.addRotationPlan.init(this.formData.routeId)
        },
        changeDate(e){
            console.log(e)
            this.beginDate = e.beginDate
            this.endDate = e.endDate
            this.getList()
        },
        clickItem(option){
            console.log(option)
            this.diaObj = {
                rotationPlanF:option.rotationPlanF || '3',
                rotationPlanT:option.date,
            }
            if(option.id){
                this.diaObj.id = option.id
            }else{
                this.diaObj.driverId = option.driverId
                this.diaObj.driverNa = option.driverNa
                this.diaObj.routeId = this.routeId
                this.diaObj.routeNa = this.routeNa
            }
            this.dialogVisible = true
        },
        save(){
            let url = ''
            if(this.diaObj.id){
                url = '/rotation/plan/update'
            }else{
                url = '/rotation/plan/insert'
            }
            this.$http.post(url,this.diaObj).then(res=>{
                this.dialogVisible = false
                this.$message({
                    type: "success",
                    message: res.msg,
                });
                this.getList()
            })
        },
    },
}
</script>
<style lang="scss" scoped="rotationPlan">
#rotationPlan{
    .dia_box{
        >div{
            padding: 10px 0;
        }
    }
}
</style>